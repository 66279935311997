var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            staticStyle: { width: "88px", height: "36px" },
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "tableWrapper" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "font-size": "12px" },
              attrs: { border: "", data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "事件编号",
                  align: "center",
                  prop: "orderCode",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "工单设备类型",
                  align: "center",
                  prop: "equipType",
                  resizable: false,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.Berth_number"),
                  align: "center",
                  width: "300px",
                  resizable: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: scope.row.berthCodes,
                              placement: "top",
                            },
                          },
                          [
                            _c("span", { staticClass: "berthCode" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("showBerthCode")(scope.row.berthCodes)
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所属子车场",
                  align: "center",
                  prop: "parkName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "所属父车场",
                  align: "center",
                  prop: "parentParkName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.state"),
                  align: "center",
                  prop: "state",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.state == "派工",
                                expression: "scope.row.state == '派工'",
                              },
                            ],
                          },
                          [_vm._v("派工")]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.state == "搁置",
                                expression: "scope.row.state == '搁置'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: scope.row.shelveDesc,
                              placement: "top",
                            },
                          },
                          [
                            _c("span", { staticStyle: { color: "#d9001b" } }, [
                              _vm._v("搁置"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "状态变更时间",
                  align: "center",
                  prop: "updatedTime",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "派工时间",
                  align: "center",
                  prop: "reportTime",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }